<template>
  <div class="login-container">
    <div class="content">
      <img
        alt="image"
        src="../assets/enrivo.png"
        class="logo"
      />
      <form autocomplete="on" v-if="page === 'signIn'" class="form">
        <label class="labels">E-mail</label>
        <input
          type="email"
          v-model="email"
          required
          autofocus
          placeholder="Digite o seu e-mail"
          autocomplete="email"
          class="input1"
        />
        <label class="labels">Senha</label>
        <input
          type="password"
          v-model="password"
          required
          autofocus
          placeholder="Digite a sua senha"
          autocomplete="current-password"
          class="input1"
        />
        <button type="button" v-on:click="login" class="botao">Entrar</button>
        <span v-on:click="setPage('forgotPass')" class="forgot">
          Equeci minha senha
        </span>
        <span v-on:click="setPage('signUp')" class="text-info">
          <span>Não possui uma conta?</span>
          <span class="login-text03">Criar conta agora</span>
        </span>
      </form>
      <form autocomplete="on" v-if="page === 'signUp'" class="form">
        <label class="labels">
          <span>Nome</span>
          <br />
        </label>
        <input
          type="text"
          v-model="nome"
          required
          autofocus
          placeholder="Digite o seu nome"
          autocomplete="name"
          class="input1"
        />
        <label class="labels">E-mail</label>
        <input
          type="email"
          v-model="email"
          required
          autofocus
          placeholder="Digite o seu e-mail"
          autocomplete="email"
          class="input1"
        />
        <label class="labels">Senha</label>
        <input
          type="password"
          v-model="password"
          required
          autofocus
          placeholder="Digite a sua senha"
          autocomplete="current-password"
          class="input1"
        />
        <button type="button" v-on:click="signUp" class="botao">Cadastrar</button>
        <span v-on:click="setPage('signIn')" class="login-text06 text-info">
          <span>Já possui uma conta?</span>
          <span class="login-text08">Entre agora!</span>
        </span>
      </form>
      <form autocomplete="on" v-if="page === 'forgotPass'" class="form">
        <label class="labels">E-mail</label>
        <input
          type="email"
          v-model="email"
          required
          autofocus
          placeholder="Digite o seu e-mail"
          autocomplete="email"
          class="input1"
        />
        <button type="button" v-on:click="forgotPass" class="botao">Recuperar Senha</button>
        <span v-on:click="setPage('signIn')" class="text-info">
          <span>Já possui uma conta?</span>
          <span class="login-text10">Entre agora!</span>
        </span>
        <span v-on:click="setPage('signUp')" class="text-info">
          <span>Não possui uma conta?</span>
          <span class="login-text13">Criar conta agora</span>
        </span>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      nome: '',
      email: '',
      password: '',
      page: 'signIn'
    }
  },
  methods: {
    setPage(actual){
      console.log("chegou aqui!")
      this.page = actual
    },
    login(){
      console.log("process.env.VUE_APP_MY_VARIABLE");
      console.log(process.env.VUE_APP_MY_VARIABLE); // Saída: MyValue
      console.log("login")
    },
    signUp(){
      console.log("cadastro")
    },
    forgotPass(){
      console.log("forgot")
    }
  },
  metaInfo: {
    title: 'login - Second Impossible Coyote',
    meta: [
      {
        property: 'og:title',
        content: 'login - Second Impossible Coyote',
      },
    ],
  },
}
</script>

<style>
.login-container {
  width: 100%;
  display: flex;
  padding: 25px;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f2f2;
}
.login-text03 {
  color: #00aeef;
}
.login-text06 {
  font-size: 14px;
  margin-top: 15px;
  text-align: left;
  font-family: Open Sans;
}
.login-text08 {
  color: #00aeef;
}
.login-text10 {
  color: #00aeef;
}
.login-text13 {
  color: #00aeef;
}
</style>
