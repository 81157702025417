<script setup>
import { reactive } from 'vue';
const Categories = reactive([{ "id": "1", "name": "Boostrap", "img": "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png" },
{ "id": "2", "name": "Vue", "img": "https://logospng.org/download/vue.js/vue-js-512.png" },
{ "id": "2", "name": "HTML", "img": "https://logospng.org/wp-content/uploads/html-5.png" },
{ "id": "2", "name": "node.js", "img": "https://logospng.org/wp-content/uploads/node-js.png" },
{ "id": "2", "name": "Express.js", "img": "https://expressjs.com/images/favicon.png" },
{ "id": "2", "name": "GCP", "img": "https://www.geekandjob.com/uploads/wiki/a73a9257693d0f4bee6f7a62a5f352eea0937c41.png" },
{ "id": "2", "name": "Azure", "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Microsoft_Azure.svg/150px-Microsoft_Azure.svg.png" },
{ "id": "2", "name": "GitHub", "img": "https://logospng.org/wp-content/uploads/github.png" }])
const popularCourse = reactive([{ "id": "a" }, { "id": "a1" }, { "id": "a2" }, { "id": "a3" }, { "id": "a4" }, { "id": "a5" }, { "id": "a6" }])

const emitAlert = () => { alert("Teste"); console.log("teste") }
</script>

<script>
export default {
  name: 'HomePage',
  metaInfo: {
    title: 'Second Impossible Coyote',
    meta: [
      {
        property: 'og:title',
        content: 'Second Impossible Coyote',
      },
    ],
  },
}
</script>
<template>
  <div class="home-container">
    <div class="home-container1">
      <header data-role="Header" class="home-header">
        <img alt="logo" src="../assets/enrivo.png" class="home-image" />
        <div class="home-icon-group">
          <div data-role="BurgerMenu" class="home-burger-menu">
            <svg viewBox="0 0 1024 1024" class="home-icon">
              <path
                d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z">
              </path>
            </svg>
          </div>
          <svg viewBox="0 0 1024 1024" class="home-icon02">
            <path
              d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z">
            </path>
          </svg>
        </div>
        <div data-role="MobileMenu" class="home-mobile-menu">
          <nav class="home-nav">
            <div class="home-container2">
              <img alt="image" src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                class="home-image1" />
              <div data-role="CloseMobileMenu" class="home-close-mobile-menu">
                <svg viewBox="0 0 1024 1024" class="home-icon04">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z">
                  </path>
                </svg>
              </div>
            </div>
          </nav>
          <div>
            <svg viewBox="0 0 950.8571428571428 1024" class="home-icon06">
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z">
              </path>
            </svg><svg viewBox="0 0 877.7142857142857 1024" class="home-icon08">
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z">
              </path>
            </svg><svg viewBox="0 0 602.2582857142856 1024" class="home-icon10">
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z">
              </path>
            </svg>
          </div>
        </div>
      </header>
    </div>
    <div class="home-main">

      <div class="home-container3 mt-12">
        <div v-for="categorie in Categories" :key="categorie.id"
          class="flex flex-row gap-2 items-center text-center flex-wrap pl-4 md:pl-0">
          <div
            class="card-categorie transition basis-[45%] md:basis-[10.93%] cursor-pointer rounded mr-3 p-5 hover:opacity-75 group hover:-translate-y-2">
            <div class="bg-white rounded-full m-0 mx-auto w-20 h-20 p-2.5 scale-90 group-hover:scale-125">
              <img :src="categorie.img" alt="" class="w-16 h-16 object-cover">
            </div>
            <h4 class="mt-4 mb-2 font-medium">
              {{ categorie.name }}
            </h4>
            <p class="text-gray-500 text-xs">
              32 Courses
            </p>
          </div>
        </div>

      </div>

      <div class="home-content">
        <!-- Start Popular Courses Section -->
        <section id="courses" class="flex mt-12 ">
          <div class="container mx-auto">
            <h2 class="font-medium my-5">Popular Courses</h2>

            <div id="popular-course">
              <div v-for="course in popularCourse" :key="course.id" @click="emitAlert"
                class="course-item group cursor-pointer">
                <div
                  class="border border-gray-100 shadow-sm rounded mr-3 transition hover:shadow-md group-hover:opacity-75">
                  <img src="../assets/images/course1.png" alt="" class="w-full rounded rounded-b-none">
                  <div class="mt-3 p-3">
                    <div class="flex justify-between">
                      <div>
                        <span class="bg-[#FFE7D2] text-[#FF8D3F] p-2 rounded text-[10px] font-bold">
                          Python
                        </span>
                      </div>
                      <div>
                        <span class="text-green-600 font-bold">
                          $2,000
                        </span>
                      </div>
                    </div>

                    <div class="mt-4 mb-4 text-xs">
                      <h2 class="text-base mt-3 font-medium">
                        Complete Python Bootcamp from Zero to hero in Python
                      </h2>
                      <p class="text-gray-400 mt-2">
                        32 Lesson
                      </p>
                    </div>

                  </div>
                </div>
              </div> <!-- Single Course Item -->
            </div>
          </div>

        </section>
        <!-- End Popular Courses Section -->
        <!-- Start New Courses Section -->
        <section id="new-courses" class="mt-12">
          <div class="container mx-auto">
            <h2 class="font-medium my-5">New Courses</h2>

            <div id="new-course">
              <div v-for="course in popularCourse" :key="course.id" @click="emitAlert"
                class="course-item group cursor-pointer">
                <div
                  class="border border-gray-100 shadow-sm rounded mr-3 transition hover:shadow-md group-hover:opacity-75">
                  <img src="../assets/images/course1.png" alt="" class="w-full rounded rounded-b-none" />
                  <div class="mt-3 p-3">
                    <div class="flex justify-between">
                      <div>
                        <span class="bg-[#FFE7D2] text-[#FF8D3F] p-2 rounded text-[10px] font-bold">
                          Python
                        </span>
                      </div>
                      <div>
                        <span class="text-green-600 font-bold"> $2,000 </span>
                      </div>
                    </div>

                    <div class="mt-4 mb-4 text-xs">
                      <h2 class="text-base mt-3 font-medium">
                        Complete Python Bootcamp from Zero to hero in Python
                      </h2>
                      <p class="text-gray-400 mt-2">32 Lesson</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Single Course Item -->
            </div>
          </div>
        </section>
      </div>
    </div>
    <footer class="home-footer">
      <img alt="logo" src="../assets/enrivo.png" class="home-image2" />
      <span class="home-text09">
        © 2023 Enrivo, Todos os direitos reservados.
      </span>
      <div class="home-icon-group2">
        <svg viewBox="0 0 950.8571428571428 1024" class="home-icon12">
          <path
            d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z">
          </path>
        </svg><svg viewBox="0 0 877.7142857142857 1024" class="home-icon14">
          <path
            d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z">
          </path>
        </svg><svg viewBox="0 0 602.2582857142856 1024" class="home-icon16">
          <path
            d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z">
          </path>
        </svg>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f8f8f8;
}

.home-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}

.home-header {
  width: 100%;
  display: flex;
  max-width: 1280px;
  align-self: center;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}

.home-image {
  height: 2rem;
}

.home-icon-group {
  display: flex;
}

.home-burger-menu {
  display: none;
}

.home-icon {
  width: 16px;
  height: 16px;
  display: flex;
}

.home-icon02 {
  width: 16px;
  height: 16px;
}

.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}

.home-image1 {
  height: 2rem;
}

.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-icon04 {
  width: 16px;
  height: 16px;
}

.home-icon06 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}

.home-icon08 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}

.home-icon10 {
  width: 16px;
  height: 16px;
}

.home-main {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;
}

.home-container3 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1180px;
  align-self: center;
  align-items: center;
  padding-top: 20px;
  padding-left: 30px;
  border-radius: 10px;
  padding-right: 30px;
  padding-bottom: 20px;
  justify-content: flex-start;
  background-color: #00AEEF;
  overflow-x: hidden;
}

.card-categorie {
  background-color: #ffffff;
}

.home-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  justify-content: center;
  align-self: center;
}

.home-conteudo-1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 16/9;
  border-radius: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.home-botton {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, #00000000, #000000);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-title {
  color: #ffffff;
  font-size: 1.25rem;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}

.home-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-family: Open Sans;
}

.home-conteudo-11 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 16/9;
  border-radius: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.home-botton1 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, #00000000, #000000);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-title1 {
  color: #ffffff;
  font-size: 1.25rem;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}

.home-description1 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-family: Open Sans;
}

.home-conteudo-12 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 16/9;
  border-radius: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.home-botton2 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, #00000000, #000000);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-title2 {
  color: #ffffff;
  font-size: 1.25rem;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}

.home-description2 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-family: Open Sans;
}

.home-conteudo-13 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 16/9;
  border-radius: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.home-botton3 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, #00000000, #000000);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-title3 {
  color: #ffffff;
  font-size: 1.25rem;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}

.home-description3 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-family: Open Sans;
}

.home-conteudo-14 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 16/9;
  border-radius: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.home-botton4 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, #00000000, #000000);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-title4 {
  color: #ffffff;
  font-size: 1.25rem;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}

.home-description4 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-family: Open Sans;
}

.home-conteudo-15 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 16/9;
  border-radius: 10px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.home-botton5 {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, #00000000, #000000);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-title5 {
  color: #ffffff;
  font-size: 1.25rem;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 700;
}

.home-description5 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-family: Open Sans;
}

.home-footer {
  width: 100%;
  display: flex;
  max-width: 1180px;
  align-self: center;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  border-radius: 10px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}

.home-image2 {
  height: 2rem;
  object-fit: cover;
}

.home-icon-group2 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-icon12 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}

.home-icon14 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}

.home-icon16 {
  width: 16px;
  height: 16px;
}

@media(max-width: 991px) {
  .home-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width: 767px) {
  .home-header {
    padding-left: 32px;
    padding-right: 32px;
  }

  .home-burger-menu {
    display: flex;
    align-items: center;
    margin-right: 16px;
    justify-content: center;
  }

  .home-content {
    padding-left: 32px;
    padding-right: 32px;
    grid-template-columns: 1fr;
  }

  .home-title {
    color: rgb(255, 255, 255);
  }

  .home-description {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.875rem;
    font-family: Open Sans;
  }

  .home-footer {
    padding-left: 32px;
    padding-right: 32px;
  }

  .home-text09 {
    text-align: center;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media(max-width: 479px) {
  .home-header {
    padding: 16px;
  }

  .home-mobile-menu {
    padding: 16px;
  }

  .home-container3 {
    overflow: scroll;
  }

  .home-gcp {
    color: rgb(51, 51, 153);
    font-size: 1rem;
    font-family: Open Sans;
  }

  .home-content {
    padding-top: 32px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 32px;
  }

  .home-title {
    font-size: 1.25rem;
  }

  .home-description {
    font-size: 0.75rem;
  }

  .home-title1 {
    font-size: 1.25rem;
  }

  .home-description1 {
    font-size: 0.75rem;
  }

  .home-title2 {
    font-size: 1.25rem;
  }

  .home-description2 {
    font-size: 0.75rem;
  }

  .home-title3 {
    font-size: 1.25rem;
  }

  .home-description3 {
    font-size: 0.75rem;
  }

  .home-title4 {
    font-size: 1.25rem;
  }

  .home-description4 {
    font-size: 0.75rem;
  }

  .home-title5 {
    font-size: 1.25rem;
  }

  .home-description5 {
    font-size: 0.75rem;
  }

  .home-footer {
    padding: 16px;
    flex-direction: column;
  }

  .home-image2 {
    margin-bottom: 16px;
  }

  .home-text09 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 16px;
  }
}
</style>
