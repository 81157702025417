import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'

// Criar uma instância do aplicativo
const app = createApp(App)

// Uso do Vue Router
app.use(router)

// Uso do vue-meta
app.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
  })

// Montar a aplicação no elemento com o ID "app"
app.mount('#app')
