import { createRouter, createWebHistory } from 'vue-router'

import NotFound from './views/not-found'
import Home from './views/home'
import Login from './views/Login'
import './style.css'
const routes = [
    {
      path: '/:pathMatch(.*)*',
      name: '404 - Not Found',
      component: NotFound,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;
